import { computed, onMounted } from 'vue'
import { useRpQuery } from '~/composables/graphql'
import { useMutation } from '@tanstack/vue-query'
import { graphql } from '~/resources/graphql'
import { useState } from '#app'

const STORAGE_KEY_VERIFIED_KIOSK_TOKEN = 'verified-kiosk-token'
const USE_STATE_VERIFIED_KIOSK_TOKEN = 'verified-kiosk-token'

export function useVerifiedKiosk() {
    const localStorageData = useState(USE_STATE_VERIFIED_KIOSK_TOKEN, () => '')

    onMounted(() => {
        if (import.meta.client) {
            localStorageData.value =
                localStorage.getItem(STORAGE_KEY_VERIFIED_KIOSK_TOKEN) ?? ''
        }
    })

    const token = computed(() => {
        const parts = localStorageData.value.split('||RPHQ||')
        if (parts.length === 2 && parts[0].length > 10) {
            return parts[0]
        } else {
            return ''
        }
    })

    const kioskName = computed(() => {
        const parts = localStorageData.value.split('||RPHQ||')
        if (parts.length === 2 && parts[1].length > 0) {
            return parts[1]
        } else {
            return ''
        }
    })

    const query = useRpQuery()
    const { mutate, isPending } = useMutation({
        mutationFn: async mutationData =>
            query(
                graphql(/** @lang GraphQL */ `
                    mutation VerifyKiosk($input: VerifyKioskInput!) {
                        verifyKiosk(input: $input) {
                            __typename
                            ... on VerifyKioskResult {
                                name
                                token
                            }
                            ... on NotFoundProblem {
                                message
                            }
                        }
                    }
                `),
                {
                    input: mutationData
                }
            )
    })

    function verifyCode(
        code: string,
        onSuccess: () => void,
        onError: () => void
    ) {
        mutate(
            { code },
            {
                onSuccess: data => {
                    if (data.verifyKiosk.__typename === 'VerifyKioskResult') {
                        localStorageData.value =
                            data.verifyKiosk.token +
                            '||RPHQ||' +
                            data.verifyKiosk.name

                        localStorage.setItem(
                            STORAGE_KEY_VERIFIED_KIOSK_TOKEN,
                            localStorageData.value
                        )

                        onSuccess()
                    } else {
                        onError()
                    }
                },
                onError: () => {
                    onError()
                }
            }
        )
    }

    function clearToken() {
        localStorageData.value = ''
        localStorage.removeItem(STORAGE_KEY_VERIFIED_KIOSK_TOKEN)
    }

    return {
        token,
        kioskName,
        verifyCode,
        isPending,
        clearToken
    }
}
