<template>
    <div :id="recaptchaId" />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { randomString } from '~/composables/support/util'

const recaptchaId = 'rphq_recaptcha_' + randomString()

const emit = defineEmits<{
    (e: 'token', payload: string): void
}>()

onMounted(() => {
    window.grecaptcha.render(recaptchaId, {
        sitekey: '6LeOcIUpAAAAACW5tY8Vb0jCdA0P64c4EqwS7KLD',
        callback: (token: string) => {
            emit('token', token)
        },
        'expired-callback': () => {
            emit('token', '')
        },
        theme: 'light'
    })
})
</script>
