<template>
    <form
        class="space-y-6"
        @submit="handleSubmit"
    >
        <div class="text-lg font-medium text-gray-600">
            {{ $t('signInFlow.noAccountFound') }}
        </div>

        <div class="text-sm">
            {{ $t('signInFlow.seeStaff') }}
        </div>

        <div
            class="text-lg font-medium"
            v-html="$t('signInFlow.guardianNote')"
        />

        <div ref="firstNameRef">
            <div>{{ $t('signInFlow.fullName') }}</div>
            <div class="grid grid-cols-3 gap-3">
                <div>
                    <input-text
                        id="first_name"
                        v-model="firstName"
                        type="text"
                        class="w-full"
                        :placeholder="$t('customerInformation.firstName')"
                        required
                    />
                    <small
                        v-if="showErrors && firstName.length < 2"
                        id="firstname-help"
                        class="text-red-600"
                        >{{
                            $t(
                                'customerInformation.twoOrMoreCharactersRequired'
                            )
                        }}</small
                    >
                </div>

                <div>
                    <input-text
                        id="middle_name"
                        v-model="middleName"
                        type="text"
                        class="w-full"
                        :placeholder="
                            $t('customerInformation.middleNameOptional')
                        "
                    />
                </div>

                <div>
                    <input-text
                        id="last_name"
                        v-model="lastName"
                        type="text"
                        class="w-full"
                        :placeholder="$t('customerInformation.lastName')"
                        required
                    />
                    <small
                        v-if="showErrors && lastName.length < 2"
                        id="lastname-help"
                        class="text-red-600"
                        >{{
                            $t(
                                'customerInformation.twoOrMoreCharactersRequired'
                            )
                        }}</small
                    >
                </div>
            </div>
        </div>

        <div class="flex flex-wrap gap-6">
            <div v-if="useDateOfBirth">
                <div>{{ $t('customerInformation.dateOfBirth') }}</div>
                <div class="flex gap-4">
                    <dropdown-date-input
                        v-model:date="dateOfBirth"
                        :show-errors="showErrors"
                    />
                </div>

                <div
                    v-if="showFutureDobError"
                    class="text-red-500"
                >
                    {{ $t('purchaseContract.futureDateOfBirthWarning') }}
                </div>

                <div
                    v-else-if="showOnlineProfileAgeMinimumError"
                    class="text-red-500"
                >
                    {{
                        $t('purchaseContract.ageAtLeastRequired', {
                            minimum: props.onlineProfileAgeMinimum
                        })
                    }}
                </div>

                <div
                    v-if="false"
                    class="text-xs"
                >
                    We require date of birth to comply with relevant laws for
                    online accounts.
                </div>
            </div>

            <div v-if="orgLevel && facilities.length > 1">
                <div>{{ $t('signInFlow.chooseFacility') }}</div>
                <div class="flex gap-4">
                    <select
                        v-model="selectedFacility"
                        class="border border-gray-300 p-2 text-[16px] focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] focus:outline-none focus:outline-offset-0"
                        :class="facilityInputClass"
                        required
                    >
                        <option value="">
                            {{ $t('facility') }}
                        </option>
                        <option
                            v-for="facility in facilities"
                            :key="facility.id"
                            :value="facility.id"
                        >
                            {{ facility.shortName }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <prime-button
            type="submit"
            :label="$t('next')"
            class="w-full"
            :loading="isPending"
        />

        <confirm-dialog
            group="ageOfMajorityChallenge"
            :pt="{
                root: {
                    class: '!w-[400px]'
                },
                footer: {
                    class: 'space-x-4'
                },
                rejectButton: {
                    outlined: true
                }
            }"
            :pt-options="{
                mergeProps: true,
                mergeSections: true
            }"
        >
            <template #message="slotProps">
                <div
                    class="flex-column align-items-center border-bottom-1 surface-border flex w-full gap-3"
                >
                    <i
                        :class="slotProps.message.icon"
                        class="text-6xl text-primary-500"
                    ></i>
                    <p>
                        You must be {{ ageOfMajorityHint }} or older to complete
                        the selected agreement. <br /><br />
                        <span class="text-red-600 font-medium"
                            >If you are a parent/guardian, please go back and
                            enter YOUR information, not the minor's.</span
                        ><br /><br />
                        Otherwise, you may continue to create an account, but
                        you will not be able to complete the agreement.
                    </p>
                </div>
            </template>
        </confirm-dialog>
    </form>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import PrimeButton from 'primevue/button'
import InputText from 'primevue/inputtext'
import { useMutation } from '@tanstack/vue-query'
import { useRpQuery } from '~/composables/graphql'
import type { FragmentType } from '~/resources/graphql'
import { graphql } from '~/resources/graphql'
import type { VerifyDateOfBirthInput } from '~/resources/graphql/graphql'
import { PossibleCustomerFragment } from '~/components/Verification/ChooseCustomer.vue'
import { useSetViewer } from '~/composables/use-login'
import { useShowAlert } from '~/composables/use-show-alert'
import { isSfrc } from '~/composables/use-org'
import DropdownDateInput from '~/components/Input/DropdownDateInput.vue'
import { useI18n } from 'vue-i18n'
import { parseTimeless } from '~/composables/support/localization'
import { differenceInYears, isAfter } from 'date-fns'
import { useConfirm } from 'primevue/useconfirm'
import ConfirmDialog from 'primevue/confirmdialog'

const props = defineProps<{
    token: string
    orgLevel: boolean
    facilities: { id: string; shortName: string }[]
    ageOfMajorityHint: number
    onlineProfileAgeMinimum: number
}>()

const emit = defineEmits<{
    (e: 'done'): void
    (
        e: 'multiple',
        payload: FragmentType<typeof PossibleCustomerFragment>
    ): void
    (e: 'createCustomer', payload: string): void
}>()

const { t } = useI18n()
const firstNameRef = ref<HTMLDivElement>()
onMounted(() => {
    // Focus input on mounted
    firstNameRef.value?.querySelector('#first_name')?.focus?.()
})

const firstName = ref('')
const middleName = ref('')
const lastName = ref('')
const dateOfBirth = ref('')
const selectedFacility = ref('')

const query = useRpQuery()
const { mutate, isPending } = useMutation({
    mutationFn: (input: VerifyDateOfBirthInput) =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation VerifyDateOfBirth($input: VerifyDateOfBirthInput!) {
                    verifyDateOfBirth(input: $input) {
                        __typename
                        ... on VerifyDateOfBirthResult {
                            record {
                                ...ViewerFragment
                            }
                        }
                        ... on VerifyDateOfBirthCreateCustomerResult {
                            record {
                                ...ViewerFragment
                            }
                        }
                        ... on VerifyDateOfBirthMultipleResult {
                            ...PossibleCustomerFragment
                        }
                        ... on ValidationError {
                            fieldErrors {
                                message
                            }
                        }
                    }
                }
            `),
            {
                input
            }
        )
})

const showFutureDobError = computed(() => {
    if (showErrors.value === false) {
        return false
    }
    if (dateOfBirth.value === '') {
        return false
    }

    const customerDob = parseTimeless(dateOfBirth.value)
    return isAfter(customerDob, new Date())
})

const showOnlineProfileAgeMinimumError = computed(() => {
    if (showErrors.value === false) {
        return false
    }

    if (dateOfBirth.value === '') {
        return false
    }

    const now = new Date()
    // Invalid Date of Birth
    const customerDob = parseTimeless(dateOfBirth.value)
    const customerAge = differenceInYears(now, customerDob)

    return customerAge < props.onlineProfileAgeMinimum
})

const showErrors = ref(false)
const useDateOfBirth = !isSfrc()
const showAlert = useShowAlert()
const setViewer = useSetViewer()
const confirm = useConfirm()
async function handleSubmit(event: Event) {
    // Prevent browser submitting form
    event.preventDefault()

    // Unfilled fields
    if (
        (useDateOfBirth && dateOfBirth.value === '') ||
        firstName.value.length < 2 ||
        lastName.value.length < 2
    ) {
        showErrors.value = true
        return
    }

    const now = new Date()
    // Invalid Date of Birth
    const customerDob = parseTimeless(dateOfBirth.value)
    const customerAge = differenceInYears(now, customerDob)

    if (isAfter(customerDob, now)) {
        showErrors.value = true
        return
    }
    if (customerAge > 120) {
        showErrors.value = true
        return
    }
    if (customerAge < props.onlineProfileAgeMinimum) {
        showErrors.value = true
        return
    }

    const mutationData: VerifyDateOfBirthInput = {
        token: props.token,
        dateOfBirth: dateOfBirth.value === '' ? null : dateOfBirth.value,
        firstName: firstName.value,
        middleName: middleName.value,
        lastName: lastName.value,
        facility: selectedFacility.value === '' ? null : selectedFacility.value
    }

    if (props.ageOfMajorityHint > 0 && customerAge < props.ageOfMajorityHint) {
        confirm.require({
            group: 'ageOfMajorityChallenge',
            header: 'Confirm Age',
            rejectClass: 'text-primary-600 bg-white',
            rejectLabel: 'Continue',
            acceptLabel: 'Edit Date of Birth',
            reject: () => {
                performMutation(mutationData)
            }
        })
    } else {
        performMutation(mutationData)
    }
}

function performMutation(mutationData: VerifyDateOfBirthInput) {
    mutate(mutationData, {
        onSuccess: data => {
            if (
                data.verifyDateOfBirth.__typename === 'VerifyDateOfBirthResult'
            ) {
                // TODO: Check customer name is filled out
                setViewer(data.verifyDateOfBirth.record)
                emit('done')
                return
            } else if (
                data.verifyDateOfBirth.__typename ===
                'VerifyDateOfBirthCreateCustomerResult'
            ) {
                setViewer(data.verifyDateOfBirth.record)
                emit('done')
                return
            } else if (
                data.verifyDateOfBirth.__typename ===
                'VerifyDateOfBirthMultipleResult'
            ) {
                emit('multiple', data.verifyDateOfBirth)
                return
            }

            showAlert(t('oops'), t('somethingWrongWarning'))
        }
    })
}

const facilityInputClass = computed(() => {
    if (showErrors.value && selectedFacility.value === '') {
        return 'border-2 border-red-500'
    } else {
        return ''
    }
})
</script>
